import toBoolean from 'lib/toBoolean';
import { SearchParams } from 'lib/toSearchParams';
import toVersion from 'lib/toVersion';
import { ALLOW_CURRENCIES, Currency, PaymentType } from 'lib/types';
import numeral from 'numeral';

export type Params = {
  apiBaseUri?: string;
  publicKey: string;
  currency: Currency;
  paymentType: PaymentType;
  email: string;
  phone: string;
  customerFirstName?: string;
  customerMiddleName?: string;
  customerLastName?: string;
  customerResidentialAddress?: string;
  showPayNow?: boolean;
  showPayPlanSubmit?: boolean;
  hideFullPayOption?: boolean;
  hidePayLaterOption?: boolean;
  emailAndSmsCheckoutTokenVerified?: boolean;
  checkoutCSSOverride?: string;
  waitForInit?: boolean;
  elementId: string;
  paycardAmount: number;
  paycardSurchargeAmount: number;
  payplanAmount: number;
  payplanSurchargeAmount: number;
  paymentSourceOnly?: boolean;
  showSubmit?: boolean;
  hideSavedCards?: boolean;
  isB2B?: boolean;
  hideB2BUsageScopeOptions?: boolean;
  primaryColor?: string;
  showApplePay?: boolean;
  preventWalletSubmit?: boolean;
  scroll?: boolean;
  version: string;
  platform: string;
  platformVersion: string;
  platformPluginVersion: string;
};

const getActivePayOption = (paymentType: string, hidePayOption: string): PaymentType => {
  const payOptions = ['payplan', 'paycard'];

  if (hidePayOption === 'paycard') {
    return 'payplan';
  } else if (hidePayOption === 'payplan') {
    return 'paycard';
  } else if (payOptions.includes(paymentType)) {
    return paymentType as PaymentType;
  }

  return 'paycard';
};

export const toParams = (searchParams: SearchParams): Params => {
  const amount = searchParams.amount ? Number(searchParams.amount) : 0;
  const paramsPaymentType = searchParams.paymentType || 'paycard';
  const paramsHidePayOption = toBoolean(searchParams.hidePayLaterOption)
    ? 'payplan'
    : toBoolean(searchParams.hideFullPayOption)
    ? 'paycard'
    : '';
  const initPaymentType = getActivePayOption(paramsPaymentType, paramsHidePayOption);
  const paymentType = initPaymentType || 'paycard';
  const email = searchParams.email || '';
  const phone = searchParams.phone || '';
  const paymentSourceOnly = toBoolean(searchParams.paymentSourceOnly);

  if (!searchParams.publicKey) {
    throw Error('Incorrect public key');
  }
  if (!paymentSourceOnly) {
    if (!amount) {
      throw Error('Incorrect checkout amount');
    }
    if (!searchParams.currency) {
      throw Error(`Currency is required`);
    }
    if (!ALLOW_CURRENCIES.includes(searchParams.currency as Currency)) {
      throw Error(`Currency ('${searchParams.currency}') is not supported`);
    }
  }

  const paycardAmount = searchParams.paycardAmount ? Number(searchParams.paycardAmount) : amount;
  const payplanAmount = searchParams.payplanAmount ? Number(searchParams.payplanAmount) : amount;
  const paycardSurchargeAmount = numeral(paycardAmount).subtract(amount).value() ?? 0;
  const payplanSurchargeAmount = numeral(payplanAmount).subtract(amount).value() ?? 0;

  return {
    publicKey: searchParams.publicKey,
    currency: searchParams.currency as Currency,
    paymentType,
    email,
    phone,
    customerFirstName: searchParams.customerFirstName,
    customerMiddleName: searchParams.customerMiddleName,
    customerLastName: searchParams.customerLastName,
    customerResidentialAddress: searchParams.customerResidentialAddress,
    showPayNow: toBoolean(searchParams.showPayNow),
    showPayPlanSubmit: toBoolean(searchParams.showPayPlanSubmit),
    hideFullPayOption: toBoolean(searchParams.hideFullPayOption),
    hidePayLaterOption: toBoolean(searchParams.hidePayLaterOption),
    emailAndSmsCheckoutTokenVerified: toBoolean(searchParams.emailAndSmsCheckoutTokenVerified),
    checkoutCSSOverride: searchParams.checkoutCSSOverride,
    waitForInit: toBoolean(searchParams.waitForInit),
    elementId: searchParams.elementId ?? '',
    paycardAmount,
    paycardSurchargeAmount: Math.max(paycardSurchargeAmount, 0),
    payplanAmount,
    payplanSurchargeAmount: Math.max(payplanSurchargeAmount, 0),
    paymentSourceOnly,
    showSubmit: toBoolean(searchParams.showSubmit),
    hideSavedCards: toBoolean(searchParams.hideSavedCards),
    isB2B: toBoolean(searchParams.isB2B),
    hideB2BUsageScopeOptions: toBoolean(searchParams.hideB2BUsageScopeOptions),
    primaryColor: searchParams.pc || '',
    showApplePay: toBoolean(searchParams.showApplePay),
    preventWalletSubmit: toBoolean(searchParams.preventWalletSubmit || ''),
    version: toVersion(searchParams.version || ''),
    platform: searchParams.platform || '',
    platformVersion: searchParams.platformVersion || '',
    platformPluginVersion: searchParams.platformPluginVersion || '',
    scroll: toBoolean(searchParams.scroll),
  };
};
